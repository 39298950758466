import Component from '../Component';

class Q2 extends Component {
  constructor(options) {
    super(options);
    this.state = {
      a: [1, 2, 3, 4, 5]
    };
  }

  answer() {
    const a = 0.2 + 0.1;
    console.log(a);
    console.log(0.2 + 0.1 === 0.3);
  }

  componentDidMount() {
    console.log('Question 2: ');
    this.answer();
  }
}
export default Q2;
