import changeCase from 'change-case';

class Component {
  constructor(options) {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.root = options.root;
    this.props = {};
    this.refs = {};

    this.componentWillMount();
  }

  createProps() {
    return new Promise((resolve) => {
      this.props = [...this.root.attributes]
        .filter(
          attr => attr.name !== 'data-component' && attr.name.indexOf('data-') !== -1
        )
        .reduce((accumulator, attr) => {
          const name = changeCase.camelCase(attr.name.split('data-')[1]);
          const value = attr.value === '' ? true : attr.value;

          return { ...accumulator, [name]: value };
        }, {});

      resolve(this.props);
    });
  }

  createRefs() {
    return new Promise((resolve) => {
      const refs = this.root.querySelectorAll('[data-ref]');

      [...refs].forEach((ref) => {
        const refName = ref
          .getAttribute('data-ref')
          .replace(/-([a-z])/g, g => g[1].toUpperCase());

        if (ref.closest('[data-component]') === this.root) {
          this.refs = {
            ...this.refs,
            [refName]: [...(this.refs[refName] || []), ref]
          };
        }
      });
      resolve(this.refs);
    });
  }

  componentWillMount() {
    this.createProps().then(() => {
      this.createRefs().then(() => {
        this.componentDidMount();
      });
    });
  }

  componentDidMount() {}
}

export default Component;
