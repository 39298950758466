import Component from '../Component';

class Q1 extends Component {
  constructor(options) {
    super(options);
    this.state = {
      a: [1, 2, 3, 4, 5]
    };
  }

  multiply() {
    // const a = [1, 2, 3, 4, 5];
    this.state.a.forEach((entry) => {
      this.state.a.push(entry * entry);
    });
    console.log(this.state.a);
  }

  componentDidMount() {
    console.log('Question 1: ');
    this.multiply();
  }
}
export default Q1;
