import Q1 from './components/Q1';
import Q2 from './components/Q2';

// styles
import '../scss/utils/_reset.scss';

import '../scss/generic/_typography.scss';
import '../scss/generic/_buttons.scss';
import '../scss/generic/_images.scss';
import '../scss/generic/_forms.scss';
import '../scss/generic/_layout.scss';

import '../scss/templates/_homepage.scss';
import '../scss/templates/_project.scss';
import '../scss/templates/_about.scss';

import '../scss/components/_header.scss';
import '../scss/components/_footer.scss';

class Boilerplate {
  constructor(components) {
    Object.keys(components).forEach((component) => {
      const Component = components[component];
      const elements = document.querySelectorAll(
        `[data-component="${component}"]`
      );

      [...elements].forEach((element) => {
        new Component({ root: element });
      });
    });
  }
}

new Boilerplate({
  q1: Q1,
  q2: Q2
});
